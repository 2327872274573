import api from "@/axios";

export default {
  findAll() {
    return api().get("location");
  },
  findOne(id) {
    return api().get(`admin/location/${id}`);
  },
  create(data) {
    return api().post("location", data);
  },
  update(id, data) {
    return api().patch(`admin/location/${id}`, data);
  },
  remove(id) {
    return api().delete(`location/${id}`);
  },
};
