export default class Track {
  constructor() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.track = {
      location_id: null,
      location: { address: "" },
      translations: [
        { id: null, name: "", lang: "ar" },
        { id: null, name: "", lang: "en" },
      ],
    };
  }

  fillData(data) {
    if (data) {
      const itemAr = data.translations?.find((o) => o.lang === "ar");
      const itemEn = data.translations?.find((o) => o.lang === "en");

      this.track.location_id = data.location_id ? data.location_id : null;
      this.track.location.address = data?.location?.address
        ? data.location.address
        : "";
      this.track.translations[0].id = itemAr ? itemAr.id : null;
      this.track.translations[0].name = itemAr ? itemAr.name : "";
      this.track.translations[1].id = itemEn ? itemEn.id : null;
      this.track.translations[1].name = itemEn ? itemEn.name : "";
    } else {
      this.setInitialValue();
    }
  }
}
