<template>
  <div>
    <b-card>
      <ValidationObserver ref="track" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
          <b-row>
            <b-col md="6">
              <input-form
                v-model="track.track.translations[0].name"
                :name="$t('tracks.nameAr')"
                :label="$t('tracks.nameAr')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>
            <b-col md="6">
              <input-form
                v-model="track.track.translations[1].name"
                :name="$t('tracks.nameEn')"
                :label="$t('tracks.nameEn')"
                validate="required"
                :disabled="disableStatus"
              />
            </b-col>

            <b-col md="12">
              <main-select
                :options="locations"
                label="address"
                v-model="track.track.location_id"
                :reduce="(i) => i.id"
                :text="track.track.location.address"
                :labelTitle="$t('locations.item')"
                validate="required"
                :showAsInfo="disableStatus"
              />
            </b-col>
          </b-row>

          <form-btns
            v-if="currentPage != itemPages.view"
            :label="submitLabel"
            :loading="loading"
          />
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <races :trackId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import trackServices from '../services/tracks'
import locationsService from '../../../generalSettings/locations/services/locations'
import Track from '../models/Track'
import Races from '../../races/views/Races.vue'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { Races },
  data () {
    return {
      track: new Track(),
      locations: []
    }
  },
  methods: {
    async create () {
      trackServices
        .create(this.track.track)
        .then((response) => {
          this.loading = false
          core.showSnackbar('success', response.data.message)
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: 'events.tracks' })
        })
        .catch(() => {
          this.loading = false
        })
    },
    async update () {
      trackServices
        .update(this.id, this.track)
        .then((response) => {
          this.loading = false
          core.showSnackbar('success', response.data.message)
          this.lastRoute.name
            ? this.$router.push(this.lastRoute)
            : this.$router.push({ name: 'events.tracks' })
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      trackServices.findOne(this.id).then((response) => {
        console.log(response.data.data)
        this.track.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }

    if (!this.disableStatus) {
      locationsService.findAll().then((res) => {
        this.locations = res.data.data
      })
    }
  }
}
</script>
